import {
  ExperimentContextType,
  FormattedExperiments,
} from '../../../context/experiment.context.ts';
import { MarketContextType } from '../../../context/market.context.ts';

export const filterExperiments = (
  xsExperiments: ExperimentContextType['experiments'],
  xsExperimentsHeader: ExperimentContextType['experimentsHeader'],
  country: MarketContextType['country'],
): ExperimentContextType['bucketedExperiments'] => {
  let bucketedExperiments: ExperimentContextType['bucketedExperiments'] = {};
  const activeTests = xsExperimentsHeader?.split(',');

  if (activeTests && activeTests.length > 0) {
    // format xsExperiments as a dictionary keyed by the numeric experimentId
    const xsExperimentsDict: FormattedExperiments = Object.values(
      xsExperiments,
    ).reduce((acc: Record<string, any>, experiment) => {
      acc[experiment.experimentId] = {
        experimentName: experiment.experimentName,
        experimentTextId: experiment.experimentTextId,
        variations: experiment.variations,
      };
      return acc;
    }, {});

    // filter out experiments and variations that are not in the experiments header (experiments that the user is not bucketed into)
    bucketedExperiments = activeTests.reduce(
      (acc: ExperimentContextType['bucketedExperiments'], test) => {
        const [experimentId, variationId] = test.split(':');
        const isBucketedExperiment = Object.prototype.hasOwnProperty.call(
          xsExperimentsDict,
          Number(experimentId),
        );
        if (!isBucketedExperiment) {
          return acc;
        }
        const variations = xsExperimentsDict[Number(experimentId)].variations;
        const bucketedVariation = Object.values(variations).filter(
          (variation) => {
            return variation.variationId === Number(variationId);
          },
        )[0];
        //filter out deactivated variations
        if (!bucketedVariation || !bucketedVariation.isActive) {
          return acc;
        }
        const experimentName =
          xsExperimentsDict[Number(experimentId)].experimentName;
        const experimentTextId =
          xsExperimentsDict[Number(experimentId)].experimentTextId;

        acc[experimentTextId] = {
          experimentTextId:
            xsExperimentsDict[Number(experimentId)].experimentTextId,
          experimentName,
          attributes: bucketedVariation.attributes,
        };
        return acc;
      },
      {},
    );
  }
  return bucketedExperiments;
};
