import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Experiment } from '@ww-digital/xs-sdk-react';
import { InView } from 'react-intersection-observer';
import _ from 'lodash';

import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { CalendarType } from '../../../hooks/useExperiment';
import type { ExperimentContextType } from '../../../context/experiment.context';

import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import { BodySlice } from '@ww-digital/web-palette-react/dist/components/Slice/BodySlice/BodySlice';
import { FootnoteSlice } from '@ww-digital/web-palette-react/dist/components/Slice/FootnoteSlice/FootnoteSlice';
import { IconCardSlice } from '@ww-digital/web-palette-react/dist/components/Slice/IconCardSlice/IconCardSlice';
import { ImageSlice } from '@ww-digital/web-palette-react/dist/components/Slice/ImageSlice/ImageSlice';
import { MemberCarouselSlice } from '@ww-digital/web-palette-react/dist/components/Slice/MemberCarouselSlice/MemberCarouselSlice';
import { NumberedStepsSlice } from '@ww-digital/web-palette-react/dist/components/Slice/NumberedStepsSlice/NumberedStepsSlice';
import { PullQuoteSlice } from '@ww-digital/web-palette-react/dist/components/Slice/PullQuoteSlice/PullQuoteSlice';
import { TestimonialSlice } from '@ww-digital/web-palette-react/dist/components/Slice/TestimonialSlice/TestimonialSlice';
import { VideoSlice } from '@ww-digital/web-palette-react/dist/components/Slice/VideoSlice/VideoSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { MastheadGridSliceContainer } from '../MastheadGridSliceContainer/MastheadGridSliceContainer.tsx';
import { BMICalculatorSliceContainer } from '../BMICalculatorSliceContainer/BMICalculatorSliceContainer.tsx';
import { CalloutSliceContainer } from '../CalloutSliceContainer/CalloutSliceContainer.tsx';
import { ColorBlockMastheadSliceContainer } from '../ColorBlockMastheadSliceContainer/ColorBlockMastheadSliceContainer.tsx';
import { ColumnIconListSliceContainer } from '../ColumnIconListSliceContainer/ColumnIconListSliceContainer.tsx';
import { ContentCardGridAutoSliceContainer } from '../ContentCardGridAutoSliceContainer/ContentCardGridAutoSliceContainer.tsx';
import { ContentCardGridManualSliceContainer } from '../ContentCardGridManualSliceContainer/ContentCardGridManualSliceContainer.tsx';
import { EmailCaptureSliceContainer } from '../EmailCaptureSliceContainer/EmailCaptureSliceContainer.tsx';
import { FAQSliceContainer } from '../FAQSliceContainer/FAQSliceContainer.tsx';
import { FeaturesListSliceContainer } from '../FeaturesListSliceContainer/FeaturesListSliceContainer.tsx';
import { FoodCardGridSliceContainer } from '../FoodCardGridSliceContainer/FoodCardGridSliceContainer.tsx';
import { FooterLegalSliceContainer } from '../FooterLegalSliceContainer/FooterLegalSliceContainer.tsx';
import { FooterMenuSliceContainer } from '../FooterMenuSliceContainer/FooterMenuSliceContainer.tsx';
import { GalleryCarouselSliceContainer } from '../GalleryCarouselSliceContainer/GalleryCarouselSliceContainer.tsx';
import { ImmersiveMastheadSliceContainer } from '../ImmersiveMastheadSliceContainer/ImmersiveMastheadSliceContainer.tsx';
import { LargeProductSquareSliceContainer } from '../LargeProductSquareSliceContainer/LargeProductSquareSliceContainer.tsx';
import { NavigationSliceContainer } from '../NavigationSliceContainer/NavigationSliceContainer.tsx';
import { PlansDifferentiatorSliceContainer } from '../PlansDifferentiatorSliceContainer/PlansDifferentiatorSliceContainer.tsx';
import { PlansDisclaimerSliceContainer } from '../PlansDisclaimerSliceContainer/PlansDisclaimerSliceContainer.tsx';
import { PlansDurationSliceContainer } from '../PlansDurationSliceContainer/PlansDurationSliceContainer.tsx';
import { PromotionBannerSliceContainer } from '../PromotionBannerSliceContainer/PromotionBannerSliceContainer.tsx';
import { SmallProductSquareSliceContainer } from '../SmallProductSquareSliceContainer/SmallProductSquareSliceContainer.tsx';
import { SmartPlateSliceContainer } from '../SmartPlateSliceContainer/SmartPlateSliceContainer.tsx';
import { SourcesSliceContainer } from '../SourcesSliceContainer/SourcesSliceContainer.tsx';
import { TableSliceContainer } from '../TableSliceContainer/TableSliceContainer.tsx';
import { TabsLinkSliceContainer } from '../TabsLinkSliceContainer/TabsLinkSliceContainer.tsx';
import { TestimonialCarouselSliceContainer } from '../TestimonialCarouselSliceContainer/TestimonialCarouselSliceContainer.tsx';
import { ToutSliceContainer } from '../ToutSliceContainer/ToutSliceContainer.tsx';
import { TwoColumnSliceContainer } from '../TwoColumnSliceContainer/TwoColumnSliceContainer.tsx';
import { VideoGridSliceContainer } from '../VideoGridSliceContainer/VideoGridSliceContainer.tsx';
import { VideoSlideshowSliceContainer } from '../VideoSlideshowSliceContainer/VideoSlideshowSliceContainer.tsx';
import { VideoMastheadSliceContainer } from '../VideoMastheadSliceContainer/VideoMastheadSliceContainer.tsx';
import { WeightHealthHomepageSliceContainer } from '../WeightHealthHomepageSliceContainer/WeightHealthHomepageSliceContainer.tsx';
import { WeightHealthHomepageBottomSliceContainer } from '../WeightHealthHomepageSliceContainer/WeightHealthHomepageBottomSliceContainer.tsx';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { ExperimentContext } from '../../../context/experiment.context.ts';
import { useExperiment } from '../../../hooks/useExperiment.tsx';
import wwUtility from '../../../ww.utility.ts';

import { xsFunctionLookupTable } from '../../../xsExperiments/xsFunctionLookupTable.ts';

export interface SliceContainerProps {
  slice: any;
  contentId?: string;
  category?: string;
  topLevelCategory?: string;
  region?: string;
  type: string;
  ref?: any;
  xsExperimentTextId?: string;
}

export interface SliceContainerComponentProps extends SliceContainerProps {
  daCategory: string;
}

interface ComponentMapType {
  BodySlice: typeof BodySlice;
  FootnoteSlice: typeof FootnoteSlice;
  IconCardSlice: typeof IconCardSlice;
  ImageSlice: typeof ImageSlice;
  MemberCarouselSlice: typeof MemberCarouselSlice;
  NumberedStepsSlice: typeof NumberedStepsSlice;
  PullQuoteSlice: typeof PullQuoteSlice;
  TestimonialSlice: typeof TestimonialSlice;
  VideoSlice: typeof VideoSlice;
}

export const processDate = (
  startDate: string,
  endDate: string,
  currentDate: number,
) => {
  return (
    new Date(startDate).valueOf() <= currentDate &&
    currentDate < new Date(endDate).valueOf()
  );
};

export const SliceContainer = ({
  slice: sliceInit,
  contentId = '',
  category = '',
  topLevelCategory = '',
  region,
  type,
  xsExperimentTextId = '',
}: SliceContainerProps): JSX.Element | null => {
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  // When adding support for a new slice, you must update the fragmentTypes.json file for
  // ApolloClient to add support for the new slice type.  You can find more info about how to do
  // this in the /src/components/Provider/Apollo/README.md.

  // Slices that need no extra data mapping. Just throw the data into the slice component.
  const sliceComponentMap: ComponentMapType = {
    BodySlice,
    FootnoteSlice,
    IconCardSlice,
    ImageSlice,
    MemberCarouselSlice,
    NumberedStepsSlice,
    PullQuoteSlice,
    TestimonialSlice,
    VideoSlice,
  };

  const [inView, setInView] = React.useState(false);

  const experimentData: ExperimentContextType = useContext(ExperimentContext);

  // XS Slice Update Methods
  const SLICE_METHODS = {
    update: 'update',
    replace: 'replace',
    remove: 'remove',
  };

  // Slices that have container components to provide some extra business logic or data mapping.
  const containerComponentMap: Record<
    string,
    React.FunctionComponent<SliceContainerComponentProps> | React.ComponentClass
  > = {
    BMICalculatorSlice: BMICalculatorSliceContainer,
    CalloutSlice: CalloutSliceContainer,
    ColorBlockMastheadSlice: ColorBlockMastheadSliceContainer,
    ColumnIconListSlice: ColumnIconListSliceContainer,
    ContentCardGridAutoSlice: ContentCardGridAutoSliceContainer,
    ContentCardGridManualSlice: ContentCardGridManualSliceContainer,
    EmailCaptureSlice: EmailCaptureSliceContainer,
    FAQSlice: FAQSliceContainer,
    FeaturesListSlice: FeaturesListSliceContainer,
    FoodCardGridSlice: FoodCardGridSliceContainer,
    FooterLegalSlice: FooterLegalSliceContainer,
    FooterMenuSlice: FooterMenuSliceContainer,
    GalleryCarouselSlice: GalleryCarouselSliceContainer,
    ImmersiveMastheadSlice: ImmersiveMastheadSliceContainer,
    LargeProductSquareSlice: LargeProductSquareSliceContainer,
    NavigationSlice: NavigationSliceContainer,
    PlansDifferentiatorSlice: PlansDifferentiatorSliceContainer,
    PlansDisclaimerSlice: PlansDisclaimerSliceContainer,
    PlansDurationSlice: PlansDurationSliceContainer,
    PromotionBannerSlice: PromotionBannerSliceContainer,
    SmallProductSquareSlice: SmallProductSquareSliceContainer,
    SmartPlateSlice: SmartPlateSliceContainer,
    SourcesSlice: SourcesSliceContainer,
    TableSlice: TableSliceContainer,
    TabsLinkSlice: TabsLinkSliceContainer,
    TestimonialCarouselSlice: TestimonialCarouselSliceContainer,
    ToutSlice: ToutSliceContainer,
    TwoColumnSlice: TwoColumnSliceContainer,
    VideoGridSlice: VideoGridSliceContainer,
    VideoMastheadSlice: VideoMastheadSliceContainer,
    VideoSlideshowSlice: VideoSlideshowSliceContainer,
    MastheadGridSlice: MastheadGridSliceContainer,
    WeightHealthHomepageSlice: WeightHealthHomepageSliceContainer,
    WeightHealthHomepageBottomSlice: WeightHealthHomepageBottomSliceContainer,
  };

  let slice = _.cloneDeep(sliceInit);
  let enterTest = false;
  let scrollExposure = false;
  const searchParam = useLocation().search;
  const pathname = useLocation().pathname;

  // XS alters.
  const { slice: xsSlices } = useExperiment(slice);

  let exposure = <></>;
  let exposureHandling = <></>;

  let threshold = 0;
  let triggerOnce = false;
  let initialInView = false;
  let fallbackInView = false;

  if (
    pathname &&
    !!xsSlices?.data?.augmentingFunctions?.function &&
    !!xsSlices?.data?.augmentingFunctions?.variant
  ) {
    try {
      xsFunctionLookupTable(
        xsSlices?.data?.augmentingFunctions?.function,
        pathname,
        xsSlices?.data?.augmentingFunctions?.variant,
        xsSlices?.data?.augmentingFunctions?.variables,
      );
    } catch (err) {
      //mark this error ?
    }
  }

  if (xsSlices?.id && xsSlices.data) {
    // Handle Scroll Exposure Option
    if (
      xsSlices?.id.replace('slice-id--', '') === slice.id &&
      xsSlices?.data?.exposure?.scroll
    ) {
      scrollExposure = true;
      threshold = xsSlices?.data?.exposure?.scroll?.threshold;
      triggerOnce = xsSlices?.data?.exposure?.scroll?.triggerOnce;
      initialInView = xsSlices?.data?.exposure?.scroll?.initialInView;
      fallbackInView = xsSlices?.data?.exposure?.scroll?.fallbackInView;
    }

    // Handle Audience Options: Check for Content-based query param content
    if (xsSlices?.data?.audience) {
      const xsAudienceCookies = Storage.getCookieValue(
        'xsAudienceCookies',
        false,
      );

      if (xsSlices?.data?.audience?.queryParam) {
        const regex = new RegExp(xsSlices?.data?.audience?.queryParam);
        enterTest = regex.test(searchParam);
      } else if (xsSlices?.data?.audience?.funneledEntry) {
        enterTest = xsSlices?.data?.audience?.funneledEntry;
      } else if (
        xsSlices?.data?.audience?.queryKey &&
        xsAudienceCookies !== null &&
        typeof xsAudienceCookies === 'string'
      ) {
        enterTest = !!xsAudienceCookies.includes(
          xsSlices?.data?.audience?.queryKey,
        );
      }

      //  Add test to xsAudienceCookie if we're in browser, we have query params present, and the item isn't already in the xsAudienceCookie list
      if (
        wwUtility.isBrowser() &&
        enterTest &&
        typeof xsAudienceCookies === 'string' &&
        !xsAudienceCookies.includes(xsSlices?.data?.audience?.queryKey)
      ) {
        const xsCookiesListGeneration =
          xsAudienceCookies + '.' + xsSlices?.data?.audience?.queryKey;
        document.cookie = `xsAudienceCookies=${xsCookiesListGeneration};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
      }

      if (wwUtility.isBrowser() && enterTest && xsAudienceCookies === null) {
        document.cookie = `xsAudienceCookies=${xsSlices?.data?.audience?.queryKey};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
      }

      if (
        experimentData.cookies &&
        (new RegExp(
          '^(?=.*xsAudienceCookies=)(?=.*' +
            xsSlices?.data?.audience?.queryKey +
            '(.|;)).*$',
        ).test(experimentData.cookies) ||
          new RegExp(
            '^(?=.*xsAudienceCookiesTemp=)(?=.*' +
              xsSlices?.data?.audience?.queryKey +
              '(.|;)).*$',
          ).test(experimentData.cookies))
      ) {
        enterTest = true;
      }
    }

    // Handle Date Options
    const timezoneOffset = new Date().getTimezoneOffset();
    const date = new Date().valueOf();
    const currentDate = date - timezoneOffset;

    if (xsSlices.data.calendar) {
      const currentDateValues = xsSlices.data.calendar.filter(
        (calendar: { dates: { start: string; end: string } }) =>
          processDate(calendar.dates.start, calendar.dates.end, currentDate),
      );

      if (currentDateValues.length !== 0) {
        currentDateValues.forEach((element: CalendarType, index: number) => {
          const mainKey: string = Object.keys(element.update)[index];
          xsSlices.data[mainKey] = currentDateValues[0].update[mainKey];
        });
      }
    }

    if (!xsSlices?.data?.audience || enterTest) {
      switch (xsSlices.data.method) {
        case SLICE_METHODS.update:
          for (let i = 0; i < Object.keys(xsSlices.data).length; ++i) {
            const mainKey: string = Object.keys(xsSlices.data)[i];
            slice[mainKey] = xsSlices.data[mainKey];
            delete slice.method;
            delete slice.audience;
          }
          break;
        case SLICE_METHODS.replace:
          slice = xsSlices.data;
          delete slice.method;
          delete slice.audience;
          break;
        case SLICE_METHODS.remove:
          slice = [];
      }

      // Run only if DisableNormalExposure doesn't exist, or is "False". We need to check
      // if DisableNormalExposure doesn't exist to ensure that older builds that don't have this
      // value defined still function properly.
      if (
        typeof xsSlices?.data?.augmentingFunctions?.disableNormalExposure ===
          'undefined' ||
        xsSlices?.data?.augmentingFunctions?.disableNormalExposure === false
      ) {
        exposure = (
          <Experiment name={xsSlices.name}>
            <></>
          </Experiment>
        );
      }
    }
  }
  if (xsExperimentTextId) {
    exposure = (
      <Experiment name={xsExperimentTextId}>
        <></>
      </Experiment>
    );
  }

  // If this slice has a direct component mapping, just throw all of the data straight into the component.
  if (slice.__typename in sliceComponentMap) {
    const SliceComponent =
      sliceComponentMap[slice.__typename as keyof ComponentMapType];

    const sliceComponent = <SliceComponent {...slice} />;

    if (scrollExposure) {
      exposureHandling = (
        <InView
          onChange={setInView}
          threshold={threshold}
          triggerOnce={triggerOnce}
        >
          {inView && exposure}
          {sliceComponent}
        </InView>
      );
    } else {
      exposureHandling = (
        <>
          {exposure}
          {sliceComponent}
        </>
      );
    }

    return <>{exposureHandling}</>;
  }

  // If this slice has has a container, then pass the slice and other data into the container component.
  if (slice.__typename in containerComponentMap) {
    const SliceContainerComponent = containerComponentMap[slice.__typename];

    // Grab the analytics category as that is the most common reason a slice needs a container.
    const daCategory = AnalyticsUtility.formatCategory(
      entitlement,
      type,
      region,
      category,
    );

    const sliceContainerComponent = (
      <SliceContainerComponent
        slice={slice}
        daCategory={daCategory}
        category={category}
        topLevelCategory={topLevelCategory}
        region={region}
        type={type}
        contentId={contentId}
      />
    );

    if (scrollExposure) {
      exposureHandling = (
        <InView
          onChange={setInView}
          threshold={threshold}
          triggerOnce={triggerOnce}
          initialInView={initialInView}
          fallbackInView={fallbackInView}
        >
          {inView && exposure}
          {sliceContainerComponent}
        </InView>
      );
    } else {
      exposureHandling = (
        <>
          {exposure}
          {sliceContainerComponent}
        </>
      );
    }

    return <>{exposureHandling}</>;
  }

  return null;
};
