import { WeightHealthHomepageProps } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/WeightHealthHomepage';

export const sectionOne: WeightHealthHomepageProps['sectionOne'] = {
  heading: {
    ariaLevel: 1,
    typog: 'headline1',
    text: 'It’s not just the weight you lose, it’s the health you gain.',
  },
  image: {
    desktop: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300295/visitor-site/prod/us/Weight%20Health%20Homepage/whh-1-1_2280_828.jpg',
      alt: 'Woman smiling and looking up at the viewer.',
    },
    mobile: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300283/visitor-site/prod/us/Weight%20Health%20Homepage/whhm-1-1_750_484.jpg',
      alt: 'Woman smiling and looking up at the viewer.',
    },
  },
  button: {
    url: 'https://www.weightwatchers.com/us/plans',
    children: 'Get started',
    type: 'primary',
    size: 'large',
  },
};

export const sectionOneV2: WeightHealthHomepageProps['sectionOneV2'] = {
  heading: {
    ariaLevel: 2,
    typog: 'mastheadHeadline',
    text: 'Weight health is personal. So are our solutions.',
  },
  bodyText: 'Which journey is right for you?',
  cards: [
    {
      heading: {
        ariaLevel: 3,
        typog: 'subhead2',
        text: 'Lose weight with our proven Points program',
      },
      bodyText: 'Starting At $10/Mo',
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1716224070/visitor-site/prod/us/Weight%20Health%20Homepage/wwh-1-1v2_996x496.png',
          alt: 'Tracking meals on WeightWatchers app',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1716224059/visitor-site/prod/us/Weight%20Health%20Homepage/wwhm-1-1v2_196x324.png',
          alt: 'Tracking meals on WeightWatchers app',
        },
      },
      button: {
        children: 'Get started',
        type: 'primary',
        size: 'small',
      },
      link: {
        url: 'https://www.weightwatchers.com/us/how-it-works/points-program',
      },
    },
    {
      heading: {
        ariaLevel: 3,
        typog: 'subhead2',
        text: 'Access weight-loss meds + Points program',
      },
      bodyText: 'Starting At $84/Mo',
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1716224071/visitor-site/prod/us/Weight%20Health%20Homepage/wwh-1-2v2_996x496.png',
          alt: 'WeightWatchers app with weight-loss medication',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1716224059/visitor-site/prod/us/Weight%20Health%20Homepage/wwhm-1-2v2_196x324.png',
          alt: 'WeightWatchers app with weight-loss medication',
        },
      },
      button: {
        children: 'Am I eligible?',
        type: 'primary',
        size: 'small',
      },
      link: {
        url: 'https://www.weightwatchers.com/us/clinic',
      },
    },
  ],
};

export const sectionTwo: WeightHealthHomepageProps['sectionTwo'] = {
  left: {
    link: {
      url: 'https://www.weightwatchers.com/us/how-it-works/points-program',
    },
    heading: {
      ariaLevel: 2,
      typog: 'headline4',
      text: 'Help managing your weight.',
    },
    bodyText:
      '[{"type":"p","children":[{"text":"The #1 doctor-recommended behavioral program."},{"text":"*","superscript":true},{"text":" Simple, science-backed changes help put your weight and health goals within reach."}]}]',
    button: {
      children: 'Get the program',
      type: 'link',
      size: 'medium',
    },
    image: {
      desktop: {
        src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300280/visitor-site/prod/us/Weight%20Health%20Homepage/whh-2-1_1244_632.jpg',
        alt: 'Man and woman enjoying an indoor picnic, propped up on pillows, and sharing a charcuterie board.',
      },
      mobile: {
        src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300284/visitor-site/prod/us/Weight%20Health%20Homepage/whhm-2-1_670_301.jpg',
        alt: 'Man and woman enjoying an indoor picnic, propped up on pillows, and sharing a charcuterie board.',
      },
    },
  },
  right: {
    link: {
      url: 'https://www.weightwatchers.com/us/clinic',
    },
    heading: {
      ariaLevel: 2,
      typog: 'headline4',
      text: 'Medical help managing your weight.',
    },
    bodyText:
      'Your clinical care team provides a customized plan (including weight-loss medications, if you qualify), plus the behavioral support you need.',
    button: {
      children: 'Learn about Clinic',
      type: 'link',
      size: 'medium',
    },
    image: {
      desktop: {
        src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300275/visitor-site/prod/us/Weight%20Health%20Homepage/whh-2-2_1244_632.jpg',
        alt: 'Woman having telehealth consultation with clinician on a laptop from her kitchen.',
      },
      mobile: {
        src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300285/visitor-site/prod/us/Weight%20Health%20Homepage/whhm-2-2_670_301.jpg',
        alt: 'Woman having telehealth consultation with clinician on a laptop from her kitchen.',
      },
    },
  },
  footerText:
    '*Based on a 2020 IQVIA survey of 14,000 doctors who recommend weight-loss programs to patients.',
};

export const sectionThree: WeightHealthHomepageProps['sectionThree'] = {
  eyebrow: 'Hurry, offer ends soon!',
  heading: {
    ariaLevel: 2,
    typog: 'headline1',
    text: '6 Months Free',
  },
  offerBanner: {
    text: {
      text: 'With select plan purchase.',
      typog: 'bodycopySmall',
    },
    popup: {
      title: 'Offer terms',
      content:
        '[{"type":"p","children":[{"text":"Offer Terms: ","bold":true},{"text":"Offer ends 4/26/24 (11:59pm EST). Get a waiver of our Starter Fee and get 12 months for the price of 6 months when you purchase a 12-Month Commitment Plan. Plans auto-renew at the end of the applicable plan period, and you will be charged monthly thereafter at the standard monthly fee until you cancel. While you may cancel your subscription at any time, cancellation will not go into effect until the end of your Commitment Plan. Thereafter, our standard cancellation policy applies. Offer available to new and rejoining members only. Offer available "},{"type":"a","attributes":{"href":"https://www.weightwatchers.com/us/participating-locations"},"children":[{"text":"in participating areas only."}]}]}]',
    },
  },
  bodyText: 'Your future self will thank you!',
  button: {
    url: 'https://www.weightwatchers.com/us/plans',
    children: 'View pricing',
    size: 'large',
    type: 'primary',
  },
};

export const sectionFour: WeightHealthHomepageProps['sectionFour'] = {
  heading: {
    ariaLevel: 2,
    typog: 'headline3',
    text: 'A membership that helps you care for your weight',
  },
  button: {
    url: 'https://www.weightwatchers.com/us/plans',
    children: 'Join now',
    size: 'large',
    type: 'primary',
  },
  images: [
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983339/visitor-site/prod/us/Weight%20Health%20Homepage/wwh-4-1v2_910x760.jpg',
          alt: 'Medical professional in a lab coat.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983342/visitor-site/prod/us/Weight%20Health%20Homepage/wwhm-4-1v2_670x484.jpg',
          alt: 'Medical professional in a lab coat.',
        },
      },
      caption:
        'Weight-loss programs designed with the highest scientific standards',
    },
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300276/visitor-site/prod/us/Weight%20Health%20Homepage/whh-3-3_910x760.jpg',
          alt: 'Two women in conversation at a WeightWatchers Workshop.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300288/visitor-site/prod/us/Weight%20Health%20Homepage/whhm-3-3_670x392.jpg',
          alt: 'Two women in conversation at a WeightWatchers Workshop.',
        },
      },
      caption:
        'Doctors, nutrition experts, and coaches who guide with compassion',
    },
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983339/visitor-site/prod/us/Weight%20Health%20Homepage/wwh-4-3v2_910x760.jpg',
          alt: 'iPhone displaying the WeightWatchers app showing recipes.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983342/visitor-site/prod/us/Weight%20Health%20Homepage/wwhm-4-3v2_670x484.jpg',
          alt: 'iPhone displaying the WeightWatchers app showing recipes.',
        },
      },
      caption:
        'Our award-winning app with practical tools that help make healthy shopping, cooking, and eating easier',
    },
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300277/visitor-site/prod/us/Weight%20Health%20Homepage/whh-3-4_910x760.jpg',
          alt: 'Woman looking up from her laptop and smiling at the viewer.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713300289/visitor-site/prod/us/Weight%20Health%20Homepage/whhm-3-4_670x392.jpg',
          alt: 'Woman looking up from her laptop and smiling at the viewer.',
        },
      },
      caption:
        'Concierge insurance support to maximize coverage for medical weight loss with WeightWatchers Clinic',
    },
  ],
};

export const sectionFive: WeightHealthHomepageProps['sectionFive'] = {
  heading: {
    ariaLevel: 2,
    typog: 'headline3',
    text: 'Starting your weight-loss program is simple',
  },
  subheading: {
    ariaLevel: 3,
    typog: 'subhead2',
    text: 'Gain better health with science, support, and a program fit for you.',
  },
  footerText: '',
  sections: [
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983339/visitor-site/prod/us/Weight%20Health%20Homepage/wwh-5-1v2_950x580.jpg',
          alt: 'iPhone displaying WeightWatchers app showing how the Points system works.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983343/visitor-site/prod/us/Weight%20Health%20Homepage/wwhm-5-1v2_670x484.jpg',
          alt: 'iPhone displaying WeightWatchers app showing how the Points system works.',
        },
      },
      number: '1',
      statistic: 'Find your solution',
      text: 'Choose our proven Points program or see if you qualify for medical weight loss through WeightWatchers Clinic.',
    },
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983341/visitor-site/prod/us/Weight%20Health%20Homepage/wwh-5-2v2_950x580.jpg',
          alt: "Woman's hand holding iPhone displaying WeightWatchers app showing Points Program information.",
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983344/visitor-site/prod/us/Weight%20Health%20Homepage/wwhm-5-2v2_670x484.jpg',
          alt: "Woman's hand holding iPhone displaying WeightWatchers app showing Points Program information.",
        },
      },
      number: '2',
      statistic: 'Get your plan',
      text: 'After you join, answer some quick questions in our app to get started with your tailored plan.',
    },
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715357458/visitor-site/prod/us/Weight%20Health%20Homepage/wwhm-4-5_950x580.jpg',
          alt: 'Two women sitting at a table looking into an iphone and laughing.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715357457/visitor-site/prod/us/Weight%20Health%20Homepage/wwh-4-5_670_x484.jpg',
          alt: 'Two women sitting at a table looking into an iphone and laughing.',
        },
      },
      number: '3',
      statistic: 'Find your support system',
      text: 'WW coaches and millions of members are here for you. Clinic members also have access to a dedicated care team.',
    },
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983341/visitor-site/prod/us/Weight%20Health%20Homepage/wwh-5-4v2_950x580.jpg',
          alt: 'iPhone displaying WeightWatchers app showing weight loss over a period of time.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1715983344/visitor-site/prod/us/Weight%20Health%20Homepage/wwhm-5-4v2_670x484.jpg',
          alt: 'iPhone displaying WeightWatchers app showing weight loss over a period of time.',
        },
      },
      number: '4',
      statistic: 'Celebrate better health',
      text: 'Focus on progress—not perfection—as you eat healthier, move more, and lose weight.',
    },
  ],
  button: {
    url: 'https://www.weightwatchers.com/us/plans',
    children: 'Join now',
    size: 'large',
    type: 'primary',
  },
};

export const sectionSix: WeightHealthHomepageProps['sectionSix'] = {
  heading: {
    ariaLevel: 2,
    typog: 'headline3',
    text: 'Our weight-loss program. Your powerful stories of weight health.',
  },
  slides: [
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713806082/visitor-site/prod/us/Weight%20Health%20Homepage/whh-6-3_1280_x1114.jpg',
          alt: 'WeightWatchers member Rachel smiling and looking at the viewer.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713806090/visitor-site/prod/us/Weight%20Health%20Homepage/whhm-6-3_658x474.jpg',
          alt: 'WeightWatchers member Rachel smiling and looking at the viewer.',
        },
      },
      name: 'WW Member Rachel K.',
      tagline: 'lost 115 lbs^',
      quote:
        'My labs are a testament that weight loss improved my health: I’ve gone off of blood pressure, cholesterol, and thyroid meds.',
      signature: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713805061/visitor-site/prod/us/Weight%20Health%20Homepage/whh-6-3-s.png',
          alt: 'Rachel',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713805061/visitor-site/prod/us/Weight%20Health%20Homepage/whh-6-3-s.png',
          alt: 'Rachel',
        },
      },
    },
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713819288/visitor-site/prod/us/Weight%20Health%20Homepage/whh-6-4_1280_x1114.jpg',
          alt: 'WeightWatchers member August in a hoody, holding a volleyball and smiling at the viewer.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713819279/visitor-site/prod/us/Weight%20Health%20Homepage/whhm-6-4_658x474.jpg',
          alt: 'WeightWatchers member August in a hoody, holding a volleyball and smiling at the viewer.',
        },
      },
      name: 'WW Member August M.',
      tagline: 'lost 164 lbs^',
      quote:
        'I’m more comfortable in my own skin. I was always doubting where I went—I don’t have that fear anymore.',
      signature: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713804869/visitor-site/prod/us/Weight%20Health%20Homepage/whh-6-2-s.png',
          alt: 'August',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713804869/visitor-site/prod/us/Weight%20Health%20Homepage/whh-6-2-s.png',
          alt: 'August',
        },
      },
    },
    {
      image: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713556307/visitor-site/prod/us/Weight%20Health%20Homepage/whh-6-1_1280_x1114.jpg',
          alt: 'Dana',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713556309/visitor-site/prod/us/Weight%20Health%20Homepage/whhm-6-1_658x474.jpg',
          alt: 'Dana',
        },
      },
      name: 'WW Member Dana R.',
      tagline: 'lost 37 lbs^',
      quote:
        'I lost weight and I’ve lowered my blood glucose, and created a lifestyle that will support a long-term, normal A1C.',
      signature: {
        desktop: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713556299/visitor-site/prod/us/Weight%20Health%20Homepage/whh-6-1-s.png',
          alt: 'WeightWatchers member Dana laughing and looking at the viewer.',
        },
        mobile: {
          src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1713556299/visitor-site/prod/us/Weight%20Health%20Homepage/whh-6-1-s.png',
          alt: 'WeightWatchers member Dana laughing and looking at the viewer.',
        },
      },
    },
  ],
  footerText:
    '^At 6 months, participants in a clinical trial of the WW program lost an average of 9.7 lbs (5% of body weight).',
};

export const sectionSeven: WeightHealthHomepageProps['sectionSeven'] = {
  heading: {
    ariaLevel: 2,
    typog: 'headline3',
    text: 'We’re here for every person and every body.',
  },
  bodyText:
    '[{"type":"p","children":[{"text":"Watching your weight is watching your "},{"text":"health","italic":true},{"text":". That’s what we believe–and what you deserve. Let’s start a new journey together."}]}]',
  button: {
    url: 'https://ww.com/makingtheshift',
    children: 'Join the conversation',
    size: 'large',
    type: 'primary',
  },
};

export const sectionEight: WeightHealthHomepageProps['sectionEight'] = {
  eyebrow: 'WeightWatchers + Oprah',
  heading: {
    ariaLevel: 2,
    typog: 'headline3',
    text: '[{"type":"p","children":[{"text":"Making the Shift:"},{"type":"br"},{"text":"A new way to think about weight"}]}]',
  },
  bodyText: 'A free virtual event, May 9',
  button: {
    url: 'https://ww.com/makingtheshift',
    children: 'Reserve a seat',
    size: 'large',
    type: 'primary',
  },
  image: {
    desktop: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1714410196/visitor-site/prod/us/Weight%20Health%20Homepage/oprah_1458x906.png',
      alt: 'Headshot of Oprah Winfrey',
    },
    mobile: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1714408676/visitor-site/prod/us/Weight%20Health%20Homepage/oprah_418x399.png',
      alt: 'Headshot of Oprah Winfrey',
    },
  },
};

export const weightHealthHomepageData: WeightHealthHomepageProps = {
  sectionOne: undefined,
  sectionOneV2,
  sectionTwo: undefined,
  sectionThree,
  sectionFour,
  sectionFive,
  sectionSix,
  sectionSeven: undefined,
  sectionEight: undefined,
};
